<template>
  <f-page :title="$t('labels.users')">
    <f-form
      @submit="
        () => {
          this.$refs.usersDatatable.refresh()
        }
      "
    >
      <f-card>
        <f-row>
          <f-col>
            <f-input
              v-model="search.fullName"
              :label="$t('labels.fullName')"
            />
          </f-col>

          <f-col>
            <f-input
              v-model="search.email"
              :label="$t('labels.email')"
            />
          </f-col>
          <f-col>
            <f-select
              v-model="search.role"
              :label="$t('labels.role')"
              :options="roles"
              value-type="string"
            />
          </f-col>
          <f-col>
            <f-select
              v-model="search.status"
              :label="$t('labels.status')"
              :options="statuses"
              value-type="string"
            />
          </f-col>
        </f-row>
        <f-row>
          <f-col>
            <f-switch
              v-model="search.isAllCompany"
              :false-label="$t('labels.allCompanies')"
              :true-label="$t('labels.allCompanies')"
            />
          </f-col>
        </f-row>
        <template
          #footer
          class
        >
          <f-button
            class="float-right"
            icon="search"
            :label="$t('labels.search')"
            type="submit"
            variant="primary"
          />
          <f-button
            class="float-right mr-2"
            icon="eraser"
            t-label="reset"
            variant="light"
            @click="clearSearch"
          />
        </template>
      </f-card>
    </f-form>
    <f-card>
      <f-datatable
        ref="usersDatatable"
        :columns="userColumns"
        data-url="/users"
        :features="{
          serverSidePagination: true,
          create: true,
          update: true
        }"
        :query-params="search"
        @create="onClickCreate"
        @update="onClickUpdate"
      />
    </f-card>
    <f-modal
      :id="userData.id ? 'updateUser' : 'createUser'"
      ref="modalUserEdit"
      :title="userData.id ? $t('labels.editUser') : $t('labels.createUser')"
      @submit="saveUser"
    >
      <template
        v-if="userData.id"
        slot="header-right"
      >
        <f-user-edit-info
          v-if="userData"
          :data="userData"
        />
        <f-button
          v-if="userData.status === 'new'"
          icon="envelope-o"
          :label="$t('labels.sendActivationMail')"
          type="submit"
          variant="warning btn-block"
          @click="onClickSendActivationMail"
        />
      </template>
      <modal-user-edit :user="userData" />
    </f-modal>
  </f-page>
</template>

<script>
import ModalUserEdit from './ModalUserEdit.vue'
import { userColumns } from '@/data/columns'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalUserEdit
  },
  data() {
    return {
      userColumns,
      userData: {},
      search: {},
      statuses: [
        { id: 'active', name: this.$i18n.t('labels.active') },
        { id: 'passive', name: this.$i18n.t('labels.passive') },
        { id: 'new', name: this.$i18n.t('labels.new') }
      ],
      roles: [
        { id: 'admin', name: this.$i18n.t('labels.admin') },
        { id: 'user', name: this.$i18n.t('labels.user') }
      ]
    }
  },
  computed: {
    ...mapGetters(['user', 'selectedProjectId'])
  },
  created() {
    this.search = {
      projectId: this.selectedProjectId,
      companyId: this.user.company?.id
    }
  },
  methods: {
    async saveUser() {
      if (
        this.userData.role === 'user' &&
        this.userData.projects?.length === 0
      ) {
        this.$flex.notification.warning(
          this.$t('messages.userProjectsRequired')
        )

        return
      }

      if (this.userData.id) {
        this.userData.updatedById = this.user.id
        await this.$http.put(`/users/${this.userData.id}`, this.userData)
      } else {
        this.userData.url = window.location.origin
        await this.$http.post('/users', this.userData)
      }

      this.$refs.modalUserEdit.hide()
      this.$refs.usersDatatable.refresh()
    },
    async onClickCreate() {
      this.userData = {
        createdById: this.user.id,
        status: 'new'
      }
      this.$refs.modalUserEdit.show()
    },
    async onClickUpdate(row) {
      this.userData = await this.$http.get(`/users/${row.id}`)
      this.$refs.modalUserEdit.show()
    },
    clearSearch() {
      this.search = {
        projectId: this.selectedProjectId,
        companyId: this.user.company?.id
      }
    },
    async onClickSendActivationMail() {
      this.$http.put('/users/sendNewUserEmail', {
        userId: this.userData.id,
        url: window.location.origin
      })

      this.$flex.notification.success(
        this.$t('messages.activationMailHasBeenSentSuccessfully')
      )
    }
  }
}
</script>
