<template>
  <div>
    <f-row>
      <f-col
        class="required"
        md="6"
      >
        <f-select
          v-model="user.role"
          :label="$t('labels.role')"
          :options="roles"
          rules="required"
          value-type="string"
        />
      </f-col>
      <f-col
        class="required"
        md="6"
      >
        <f-select
          v-if="user.role === userRole.USER"
          v-model="user.companyId"
          :data-params="{ companyId: this.$store.getters.user.companyId }"
          :data-url="'/companies/withoutCount'"
          :label="$t('labels.company')"
          rules="required"
        />
      </f-col>
    </f-row>
    <f-row>
      <f-col
        class="required"
        md="6"
      >
        <f-input
          v-model="user.firstName"
          :label="$t('labels.firstName')"
          rules="required"
        />
      </f-col>
      <f-col
        class="required"
        md="6"
      >
        <f-input
          v-model="user.lastName"
          :label="$t('labels.lastName')"
          rules="required"
        />
      </f-col>
    </f-row>
    <f-row>
      <f-col
        class="required"
        md="12"
      >
        <f-input
          v-model="user.email"
          :label="$t('labels.email')"
          rules="required"
        />
      </f-col>
    </f-row>
    <f-col
      v-if="user.status !== null"
      md="6"
    >
      <f-switch
        v-if="user.id"
        v-model="status"
        :false-label="$t('labels.passive')"
        :label="$t('labels.status')"
        :true-label="
          user.status === 'active' ? $t('labels.active') : $t('labels.new')
        "
        @change="onChangeStatus()"
      />
    </f-col>
    <f-card
      v-if="user.companyId"
      :title="$t('labels.projects') + ' ' + '*'"
    >
      {{ user.project }}
      <f-multiselect-listbox
        v-model="user.projects"
        :data-url="`/select/projects?companyId=${user.companyId}`"
        rules="required"
      />
    </f-card>
  </div>
</template>

<script>
import { userStatus, userRole } from '@/data/enums'

export default {
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      userRole,
      statuses: [
        { id: 'active', name: this.$i18n.t('labels.active') },
        { id: 'passive', name: this.$i18n.t('labels.passive') },
        { id: 'new', name: this.$i18n.t('labels.new') }
      ],
      roles: [
        { id: 'user', name: this.$i18n.t('labels.user') },
        { id: 'admin', name: this.$i18n.t('labels.admin') }
      ],
      status: false
    }
  },
  created() {
    this.userStatusCheck()
  },
  methods: {
    userStatusCheck() {
      if (this.user.status === 'active' || this.user.status === 'new') {
        this.status = true
      }
    },
    onChangeStatus() {
      if (!this.status) {
        this.user.status = userStatus.PASSIVE

        return
      }

      this.user.status = this.user.isActivated
        ? userStatus.ACTIVE
        : userStatus.NEW
    }
  }
}
</script>
