var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-page',{attrs:{"title":_vm.$t('labels.users')}},[_c('f-form',{on:{"submit":function () {
        this$1.$refs.usersDatatable.refresh()
      }}},[_c('f-card',{scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('f-button',{staticClass:"float-right",attrs:{"icon":"search","label":_vm.$t('labels.search'),"type":"submit","variant":"primary"}}),_c('f-button',{staticClass:"float-right mr-2",attrs:{"icon":"eraser","t-label":"reset","variant":"light"},on:{"click":_vm.clearSearch}})]},proxy:true}])},[_c('f-row',[_c('f-col',[_c('f-input',{attrs:{"label":_vm.$t('labels.fullName')},model:{value:(_vm.search.fullName),callback:function ($$v) {_vm.$set(_vm.search, "fullName", $$v)},expression:"search.fullName"}})],1),_c('f-col',[_c('f-input',{attrs:{"label":_vm.$t('labels.email')},model:{value:(_vm.search.email),callback:function ($$v) {_vm.$set(_vm.search, "email", $$v)},expression:"search.email"}})],1),_c('f-col',[_c('f-select',{attrs:{"label":_vm.$t('labels.role'),"options":_vm.roles,"value-type":"string"},model:{value:(_vm.search.role),callback:function ($$v) {_vm.$set(_vm.search, "role", $$v)},expression:"search.role"}})],1),_c('f-col',[_c('f-select',{attrs:{"label":_vm.$t('labels.status'),"options":_vm.statuses,"value-type":"string"},model:{value:(_vm.search.status),callback:function ($$v) {_vm.$set(_vm.search, "status", $$v)},expression:"search.status"}})],1)],1),_c('f-row',[_c('f-col',[_c('f-switch',{attrs:{"false-label":_vm.$t('labels.allCompanies'),"true-label":_vm.$t('labels.allCompanies')},model:{value:(_vm.search.isAllCompany),callback:function ($$v) {_vm.$set(_vm.search, "isAllCompany", $$v)},expression:"search.isAllCompany"}})],1)],1)],1)],1),_c('f-card',[_c('f-datatable',{ref:"usersDatatable",attrs:{"columns":_vm.userColumns,"data-url":"/users","features":{
        serverSidePagination: true,
        create: true,
        update: true
      },"query-params":_vm.search},on:{"create":_vm.onClickCreate,"update":_vm.onClickUpdate}})],1),_c('f-modal',{ref:"modalUserEdit",attrs:{"id":_vm.userData.id ? 'updateUser' : 'createUser',"title":_vm.userData.id ? _vm.$t('labels.editUser') : _vm.$t('labels.createUser')},on:{"submit":_vm.saveUser}},[(_vm.userData.id)?_c('template',{slot:"header-right"},[(_vm.userData)?_c('f-user-edit-info',{attrs:{"data":_vm.userData}}):_vm._e(),(_vm.userData.status === 'new')?_c('f-button',{attrs:{"icon":"envelope-o","label":_vm.$t('labels.sendActivationMail'),"type":"submit","variant":"warning btn-block"},on:{"click":_vm.onClickSendActivationMail}}):_vm._e()],1):_vm._e(),_c('modal-user-edit',{attrs:{"user":_vm.userData}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }